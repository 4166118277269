.header {
    height: 100px;
    width: 100%;
    background-color: #FFF;
    padding: 0.5rem;
    position: fixed;
    top: 0;
}

.logo {
    max-width: 80%;
    height: 100%;
    cursor: pointer;
}

.contentPrivacy {
    width: 100%;
    font-family: 'Poppins';
    margin-top: 120px;
    padding-left: 24rem;
    padding-right: 24rem;
}

.title {
    text-align: center;
    text-transform: uppercase;
}

.subtitle {
    text-transform: uppercase;
}

.info {
    text-align: justify;
    margin-top: 0.5em !important;
}

.contentFooter {
    margin-top: 25px;
    padding-bottom: 2rem;
}

.footer {
    font-size: 16px !important;
}

@media (max-width: 1220px) {
    .contentPrivacy {
        padding-left: 12rem;
        padding-right: 12rem;
    }
}
@media (max-width: 740px) {
    .contentPrivacy {
        padding-left: 4rem;
        padding-right: 4rem;
    }
}