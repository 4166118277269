.container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}
.contentMessage {
    width: 500px;
    height: 400px;
    background-color: #284EB2;
    border-radius: 30px;

    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    margin: 1rem 1rem;
}

.contentMessage p {
    font-weight: 700;
    font-size: 20px;
}